#products {
    font-family: Poppins;
}

#products .pro-hero {
    padding: 6em 0;

    background-image: url(../images/product-img/product-hero1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    color: rgb(254, 254, 254);
}

#products .pro-hero .pro-div {
    height: 90%;
}

#products .pro-hero h1 {
    letter-spacing: 0.00635em;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
    font-weight: 700;
}

#products .pro-hero p {
    letter-spacing: 0.00635em;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
    font-weight: 400;
    line-height: 1.6;
}

#products .pro-hero .bottom {
    letter-spacing: 0.00635em;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
    font-weight: 300;
    font-size: 16px;
}

@media (max-width: 568px) {
    #products .pro-hero .pro-div {
        height: 75%;
    }
}

@media (max-width: 400px) {
    #products .pro-hero .pro-div {
        height: 70%;
    }
}

@media (max-width: 400px) {
    #products .pro-hero .pro-div {
        height: 65%;
    }
}
/* 
#products .pro-list .sect {
    box-shadow: 0px 24px 48px rgba(17, 16, 62, 0.12);
} */

#products .pro-list img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

#products .pro-list h3 {
    font-weight: 700;
    color: #af2b2b;
    padding: 0.5em 0;
    margin: 0;
}

#products .pro-list p {
    font-weight: 400;
    line-height: 1.6;
}

@media (max-width: 568px) {
    #products .pro-list img {
        width: 120px;
        height: 120px;
    }

    #products .pro-list p {
        padding: 0 1em;
    }
}

#products .pro-banner {
    height: 250px;
    background-image: url(../images/product-img/product-footer.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    color: rgb(254, 254, 254);
    background-color: rgba(0, 0, 0, 0.534);
    background-blend-mode: lighten;
}

#products .circle {
    border-radius: 50%;
    object-fit: cover;
}

#products .circle1 {
    width: 360px;
    height: 360px;
    border: 12px solid #ffff;
    position: relative;
    z-index: 1000;
}

#products .fire {
    width: 420px;
    height: auto;
    margin: auto;
    border: 12px solid #ffff;
    position: relative;
    /* z-index: 1000; */
}

#products .circle2 {
    width: 260px;
    height: 260px;
    position: absolute;
    top: 40%;
    left: 40%;
}

#products .fab-circles {
    width: 50%;
}

#products .fab-text {
    width: 40%;
    font-size: 16px;
    line-height: 1.6;
    color: #000;
}

@media (max-width: 992px) {
    #products .fab-circles,
    #products .fab-text {
        width: 100%;
    }

    #products .circle1 {
        width: 300px;
        height: 300px;
    }

    #products .circle2 {
        width: 200px;
        height: 200px;
        left: 30%;
    }
}

@media (max-width: 568px) {
    #products .circle1 {
        width: 260px;
        height: 260px;
    }

    #products .circle2 {
        width: 160px;
        height: 160px;
        left: 40%;
    }

    #products .fire {
        width: 260px;
        height: 200px;
        left: 15%;
    }
}

#products .fab-detail h3 {
    /* text-align: center;
    color: rgb(51, 51, 51);
    line-height: 1.2;
    font-size: 20px;
    font-family: 'Open Sans'; */
}
