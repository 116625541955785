@font-face {
    font-family: 'Old Standard Regular';
    src: url(../font/OldStandard-Regular.ttf);
}

#media .media-text {
    color: rgb(51, 51, 51);
}

#media .media-text h1 {
    line-height: 1.2;
    font-family: 'Lato';
    font-weight: 300;
    color: #000;
}

#media .media-text hr {
    color: #000;
    opacity: 1;
}

#media .media-article1 .plain-bg {
    width: 20%;
    font-family: 'Lato';
    font-weight: 300;
}

#media .media-article1 .dark-bg {
    width: 55%;
    background: rgb(48, 48, 48);
    color: rgb(254, 254, 254);
    font-family: 'Lato';
    font-weight: 300;
}

#media .media-article2 .mini-article {
    width: 45%;
    line-height: 1.61;
    letter-spacing: 0px;
    font-size: 14px;
    font-family: 'Open Sans';
}

#media .media-text .grey-banner {
    background-color: rgb(242, 242, 242);
    font-family: 'Old Standard Regular';
    transition: none 0s ease 0s;
    color: rgb(204, 204, 204);
    font-size: 10vw;
}

#media .media-text .grey-banner p {
    font-size: 5vw;
    color: rgb(140, 140, 140);
}

@media (max-width: 992px) {
    #media .media-article1 .plain-bg,
    #media .media-article1 .dark-bg,
    #media .media-article2 .mini-article {
        width: 100%;
    }

    #media .media-text .grey-banner {
        font-size: 10rem;
    }

    #media .media-text .grey-banner p {
        font-size: 8vw;
    }
}

#media .download-hero {
    background-image: url(../images/download-hero.jpg);
    background-color: #474646;
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    height: 350px;
}

#media .download-hero img {
    width: 5%;
    transform: rotateZ(90deg);
}

#media .download-hero h1 {
    color: rgb(254, 254, 254);
    font-family: 'Lato';
    font-weight: 300;
    text-shadow: 0px 0px 5px rgb(0, 0, 0);
}

#media .download-sect .grey-banner {
    background-color: rgb(240, 240, 240);
}

#media .download-sect .grey-banner .no-bg {
    width: 46%;
}

#media .download-sect .grey-banner .grey-bg {
    width: 39%;
    background-color: rgb(201, 201, 201);
    border: 1px solid rgb(250, 250, 250);
    color: rgb(254, 254, 254);
}

#media .download-sect .grey-banner .grey-bg h3,
#media .download-sect .grey-banner .no-bg h3,
#media .download-sect .grey-banner .grey-bg p,
#media .download-sect .grey-banner .no-bg p {
    font-family: 'Lato';
    font-weight: 300;
}

#media .download-sect .grey-banner .no-bg p {
    border-top: 1px solid rgb(250, 250, 250);
}

@media (max-width: 568px) {
    #media .download-hero img {
        width: 10%;
        transform: rotateZ(90deg);
    }

    #media .download-sect .grey-banner .no-bg,
    #media .download-sect .grey-banner .grey-bg {
        width: 100%;
    }
}

#media .download-details .detail {
    width: 47%;
    border-bottom: 1px solid rgb(224, 130, 20);
}

#media .download-details .red-box {
    color: #fff;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(232, 27, 27);
    border-radius: 11px;
    border: 5px solid rgb(254, 254, 254);
    box-shadow: rgb(41 31 10 / 20%) 3px 3px 0px 0px;
}

#media .download-details .detail-text {
    width: 76%;
    color: rgb(51, 51, 51);
}

#media .download-details h3,
#media .download-details p {
    font-weight: 300;
    font-family: 'Lato';
}

#media .download-details p {
    font-size: 14px;
}

@media (max-width: 992px) {
    #media .download-details .detail {
        width: 100%;
    }

    #media .download-details .detail-text {
        width: 70%;
        color: rgb(51, 51, 51);
    }
}
