#home {
    font-family: Poppins;
}

#home .home-hero {
    height: 400px;
    background-color: #9e9e9e;

    background-image: url(../images/home-img/home-hero.png);
    background-repeat: no-repeat;
    background-blend-mode: multiply;

    background-size: cover;
    background-position-y: center;
    color: rgb(254, 254, 254);
}

#home .home-hero h1 {
    font-weight: 700;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.555);
}

#home .home-hero p {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.555);
}

@media (max-width: 992px) {
    #home .home-hero {
        height: 300px;
    }
}

#home .service1 .service-img {
    width: 35%;
}
@media (max-width: 575px) {
    #home .service1 .service-img {
        width: 100%;
        margin-bottom: 1em;
    }
}

#home .service1 h2 {
    font-weight: 600;
    color: #af2c2b;
}

#home .service1 p {
    font-weight: 400;
    color: #666666;
    line-height: 1.6;
}

#home .service1 .main-service-img {
    width: 50%;
    object-fit: cover;
}

#home .service1 .service1-details {
    width: 45%;
}

@media (max-width: 992px) {
    #home .service1 .main-service-img,
    #home .service1 .service1-details {
        width: 100%;
    }
}

#home .service2 {
    animation: bounce-top 0.9s both;
}

#home .service2 .service {
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.9375rem;
    transition: 1000ms;
    padding: 3em 0.8em;
    height: 400px;
    box-shadow: 0px 24px 48px rgba(17, 16, 62, 0.12);
}

#home .service2 .service .grey-circle {
    border-radius: 50%;
    margin: 0 auto;
    padding: 1.5em 1.2em;
    transition: 1s;
    background-color: #af2c2b;
}

#home .service2 .service .grey-circle p {
    color: rgb(254, 254, 254);
    border: none;
    font-weight: 700;
    font-family: 'Poppins';
    line-height: 0;
    margin: 0;
    padding: 0;
}

#home .service2 h5 {
    /* font-weight: 00; */
    color: #000;
}

#home .service2 .service p {
    border-top: 1px solid rgb(201, 201, 201);
    color: rgb(150, 150, 150);
    line-height: 1.6;
    font-size: 16px;
}

/* #home .service2 .service:hover > .grey-circle {
    background-color: rgb(232, 27, 27);
} */

@media (max-width: 768px) {
    #home .service2 .service {
        width: 100%;
    }
}

#home .home-clients h4 {
    color: #af2c2b;
    line-height: 1.2;
    font-weight: 700;
}

#home .home-clients img {
    width: 12%;
}

#home .home-clients img:hover {
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
}

@keyframes shadow-drop-2-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

#home .dark-bg {
    background-color: #af2b2be8;
}
#home .dark-bg .fire-card {
    text-align: center;
    width: 31%;
    background-color: rgb(254, 254, 254);
}

#home .dark-bg img {
    border: 2px solid rgb(254, 254, 254);
    box-shadow: rgb(0 0 0 / 26%) 0px 0px 5px 0px;
    width: 100%;
}

#home .dark-bg h4 {
    font-weight: 700;
    color: #af2c2b;
}

#home .dark-bg p {
    color: rgb(153, 153, 153);
    line-height: 1.4;
}

@media (max-width: 992px) {
    #home .dark-bg .fire-card {
        width: 90%;
    }
}

@keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}
