/* #footer {
    border-top: 1px solid rgb(201, 201, 201);
}

#footer .social-banner a {
    text-decoration: none;
    padding: 1em;
    border-radius: 50%;
    background-color: #c7c2c2;
    color: #fff;
    transition: 0.5s;
}
#footer .social-banner a:hover {
    background-color: #f2050e;
}

#footer .social-icon {
    font-size: 1.5rem;
}

#footer p {
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 300;
    margin-bottom: 0;
}

#footer p a {
    text-decoration: none;
    font-weight: 700;
    color: #f2050e;
    transition: color 500ms;
}

#footer p a:hover,
#footer p a:active {
    color: #c7c2c2;
} */

/* Footer */
footer {
    background-color: #212121;
    font-family: 'gill-sans-mt', sans-serif;
    font-family: Poppins;
    font-weight: 400;
    border-top: 4px solid #af2c2b;
}
.footer-logo {
    width: 18em;
}

footer .details {
    margin-bottom: 0;
}

footer .details a {
    text-decoration: none;
    font-weight: 600;
    color: #af2c2b;
    transition: color 500ms;
}

footer .details a:hover,
footer .details a:active {
    color: #fff;
}
.social-links {
    height: 95.4px;
}

.social-links a {
    text-decoration: none;
    color: #fff;
}

.social-icon {
    font-size: 2rem;
}

#footer-nav a {
    text-decoration: none;
    color: #fff;
}

#footer-nav a:hover,
#footer-nav a:focus {
    text-decoration: underline;
    text-decoration-color: #af2c2b;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
}

@media screen and (max-width: 991px) {
    .footer-logo {
        max-width: 15em;
    }

    #footer-nav .nav-link,
    .copyright {
        font-size: 1rem;
    }

    .social-links {
        height: 79.5px;
    }
}

@media (max-width: 767px) {
    footer,
    .copyright {
        font-size: 14px;
    }
}

@media screen and (max-width: 575px) {
    .social-links {
        height: initial;
    }

    .social-icon {
        font-size: 1.8rem;
    }

    .copyright {
        font-size: 2.8vw;
    }
}

footer .logo-banner img {
    width: 8%;
}

footer .logo-banner img:nth-of-type(4),
footer .logo-banner img:nth-of-type(7),
footer .logo-banner img:nth-of-type(8) {
    width: 5%;
}

footer .logo-banner img:nth-of-type(6) {
    width: 4%;
}

footer .black-banner {
    background-color: #000;
}

footer .img-div {
    width: 75%;
}

@media (max-width: 992px) {
    footer .logo-banner img {
        width: 10%;
    }

    footer .logo-banner img:nth-of-type(4),
    footer .logo-banner img:nth-of-type(7),
    footer .logo-banner img:nth-of-type(8) {
        width: 6%;
    }

    footer .logo-banner img:nth-of-type(6) {
        width: 5%;
    }
}

@media (max-width: 576px) {
    footer .logo-banner img {
        width: 12%;
    }

    footer .logo-banner img:nth-of-type(4),
    footer .logo-banner img:nth-of-type(7),
    footer .logo-banner img:nth-of-type(8) {
        width: 7%;
    }

    footer .logo-banner img:nth-of-type(6) {
        width: 6%;
    }

    footer .img-div {
        width: 100%;
    }
}
