#about {
    font-family: Poppins;
}
#about .about-hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/about-img/about-hero.png);
    height: 400px;
    background-position-x: center;
    background-position-y: -300px;
    background-color: rgba(0, 0, 0, 0.534);
}

#about .about-hero h1 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.00635em;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
}

@media (max-width: 992px) {
    #about .about-hero {
        height: 250px;
        background-position-y: center;
    }
}

/* #about .who-we-are img {
    width: 45%;
    opacity: 0.6;
} */

#about .who-we-are .wwa-text {
    width: 100%;
    color: #333333;
}

#about .who-we-are h2 {
    /* font-family: Lato; */
    font-weight: 600;
    color: #af2c2b;
}

#about .who-we-are p {
    /* font-family: 'Open Sans'; */
    color: #9e9e9e;
    line-height: 1.75;
    font-size: 1.25rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
}

@media (max-width: 600px) {
    /* #about .who-we-are img,
    #about .who-we-are .wwa-text {
        width: 100%;
    } */

    #about .who-we-are p {
        font-size: 1rem;
    }
}

#about .about-footer {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/about-img/footer-hero1.png);
    /* height: 500px; */
    background-position-x: center;
    background-position-y: center;
    background-color: rgba(0, 0, 0, 0.534);
    color: #fefefe;
    padding: 5em 0;
}

#about .about-footer .red-box {
    padding: 5em;
    background: rgba(175, 43, 43, 0.404);
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.2);
}

#about .about-footer .footer-left,
#about .about-footer .footer-right {
    width: 45%;
}

#about .about-footer h3 {
    /* font-family: 'Open Sans'; */
    font-weight: 600;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
}

#about .about-footer h5 {
    /* font-family: 'Lato'; */
    font-weight: 500;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
}

#about .about-footer p {
    /* font-family: 'Lato'; */
    font-weight: 300;
    line-height: 1.6;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
}

/* @media (max-width: 992px) {
    #about .about-footer {
        height: initial;
    }
} */

@media (max-width: 767px) {
    #about .about-footer .footer-left,
    #about .about-footer .footer-right {
        width: 100%;
    }
}

@media (max-width: 568px) {
    #about .about-footer .red-box {
        padding: 4em 1.5em;
    }
}
