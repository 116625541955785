/* Header */
header {
    font-family: Poppins;
    border-bottom: 4px solid #af2c2b;
}

.header-logo {
    width: 10em;
}

#header-nav {
    /* width: 30em; */
    color: #9e9e9e;
}

#header-nav a {
    text-decoration: none;
    color: #9e9e9e;
    font-weight: 600;
    border-radius: 5px;
    transition: 300ms;
}

#header-nav a.active {
    color: #fff;
    background-color: #af2c2b;
}

#header-nav a:hover,
#header-nav a:focus {
    color: #fff;
    background-color: rgba(175, 44, 43, 0.8);
}
header .navbar-toggler {
    border: none;
    box-shadow: none;
    margin-top: 10px;
}

header .navbar-toggler:focus-visible,
header .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

@media screen and (max-width: 991px) {
    #header-nav {
        width: initial;
        height: 10em;
    }

    .header-logo {
        max-width: 8em;
    }

    #offcanvas {
        font-family: Poppins;

        background-color: #af2c2b;
        transition: 900ms;
        overflow-y: scroll;
    }

    #header-nav a {
        color: #fff;
    }

    #header-nav a.active {
        color: #af2c2b;
        background-color: #fff;
    }

    #header-nav a:hover,
    #header-nav a:focus {
        color: #af2c2b;
        background-color: #fff;
    }

    .btn-close {
        opacity: 1;
    }
}

@media (min-width: 991px) {
    .offcanvas-backdrop.show {
        display: none;
    }
}

/* Footer */

/* #footer {
    border-top: 1px solid rgb(201, 201, 201);
}

#footer .social-banner a {
    text-decoration: none;
    padding: 1em;
    border-radius: 50%;
    background-color: #c7c2c2;
    color: #fff;
    transition: 0.5s;
}
#footer .social-banner a:hover {
    background-color: #f2050e;
}

#footer .social-icon {
    font-size: 1.5rem;
}

#footer p {
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 300;
    margin-bottom: 0;
}

#footer p a {
    text-decoration: none;
    font-weight: 700;
    color: #f2050e;
    transition: color 500ms;
}

#footer p a:hover,
#footer p a:active {
    color: #c7c2c2;
} */

/* Footer */
.offcanvas-body footer {
    background-color: transparent;
    font-family: 'gill-sans-mt', sans-serif;
    font-family: Poppins;
    font-weight: 400;
    border: none;
}

.offcanvas-body .details a {
    text-decoration: none;
    font-weight: 600;
    color: #fff;
    transition: color 500ms;
}

.offcanvas-body footer .details a:hover,
.offcanvas-body footer .details a:active {
    color: #000;
}
.social-links {
    height: 95.4px;
}

.social-links a,
.social-links a:hover,
.social-links a:focus,
.social-links a:active {
    text-decoration: none;
    color: #fff;
}

.social-icon {
    font-size: 2rem;
}

.offcanvas-body footer,
.offcanvas-body .copyright {
    font-size: 14px;
}
