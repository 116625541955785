#operations {
    font-family: Poppins;
}

#operations .op-hero {
    padding: 6em 0;
    background: #7c7c7c url(../images/operation-img/hero1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    background-position-y: center;
    background-position-x: center;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
}

#operations .op-hero h1,
#operations .op-hero p {
    color: rgb(254, 254, 254);
    letter-spacing: 0.00635em;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
}

#operations .op-hero h1 {
    font-weight: 700;
}

#operations .op-hero p {
    line-height: 1.6;
}

#operations .recent-operations {
    color: #212529;
}

#operations .recent-operations p {
    font-weight: 400;
}

#operations .recent-operations h2 {
    font-weight: 700;
    color: #af2c2b;
}

#operations .recent-operations .card {
    width: 95%;
    border-radius: 15px;
    border: none;
    box-shadow: 0px 24px 48px rgba(17, 16, 62, 0.12);
}

#operations .recent-operations .card-body {
    padding: 2.5em 2em;
}

#operations .recent-operations a,
#operations .recent-operations .card-title {
    color: #af2c2b;
}

#operations .recent-operations .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
}

#operations .recent-operations .card-text {
    color: #9e9e9e;
}

#operations .recent-operations a {
    font-weight: 700;
}

#operations .recent-operations img {
    height: 280px;
    object-fit: cover;
    border-radius: 15px;
}

#operations .recent-operations a:hover {
    color: rgba(175, 44, 43, 0.8);
}

@media (max-width: 992px) {
    #operations .recent-operations .card-body {
        padding: 2em 1.5em;
    }

    #operations .recent-operations .card-title {
        font-size: 1.3rem;
    }
}

#operations .operation {
    width: 65%;
}

#operations .operation h2 {
    font-weight: 700;
    color: #af2c2b;
}

#operations aside {
    width: 30%;
    background-color: rgba(175, 44, 43);
    color: #fefefe;
}

#operations .operation .op {
    width: 45%;
    text-align: center;
}

#operations .operation .op img {
    width: 100%;
    height: 209px;
    object-fit: cover;
}

#operations .operation .op h3 {
    color: #af2c2b;
    /* font-family: 'Open Sans'; */
    font-weight: 600;
}

#operations .operation .op .category {
    color: #fefefe;
    /* font-family: 'Open Sans'; */
    background-color: rgb(102, 102, 102);
    border-radius: 4px;
    font-weight: 500;
    width: fit-content;
}

#operations .operation .op p {
    color: rgb(102, 102, 102);
    /* font-family: 'Open Sans'; */
}

#operations aside img {
    width: 100%;
    height: 295px;
    object-fit: cover;
}

@media (max-width: 992px) {
    #operations .operation,
    #operations aside {
        width: 100%;
    }

    #operations .recent-operations img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 767px) {
    #operations .operation .op {
        width: 100%;
    }
}
