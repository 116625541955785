/* #contact {
    font-family: 'Lato', sans-serif;
}

#contact .contact-map .mapouter {
    position: relative;
    text-align: right;
    height: 300px;
    width: 100%;
    background-color: #cccccc;
}

#contact .contact-map .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 300px;
    width: 100%;
}

#contact .banner {
    position: relative;
    bottom: 50px;
    width: 259px;
    text-align: center;
    background-color: #f5ebd6;
    border-top: 6px solid rgb(122, 92, 31);
    min-height: 350px;
}

#contact .banner p {
    font-size: 50px;
    color: #333333;
    font-weight: 300;
    margin: 0;
    padding: 0.5em 0;
    line-height: 1.2;
}

#contact .banner .bg-border {
    width: 100%;
    height: 250px;
    background-image: url(../images/contact-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}

#contact .contact-form {
    width: 750px;
    color: rgb(102, 102, 102);
    line-height: 1.2;
    letter-spacing: 0px;
    font-size: 14px;
    font-family: 'Open Sans';
}

#contact .contact-form input,
#contact .contact-form textarea {
    border-radius: 0px;
    padding: 5px;
    border: 1px solid #cccccc;
}
#contact .contact-form .form-control {
    transition: 0.5s;
}

#contact .contact-form label:hover ~ .form-control,
#contact .contact-form .form-control:hover {
    border: 1px solid #000;
}

#contact .contact-form textarea {
    height: 224px;
}

#contact .contact-form button {
    padding: 1em;
    border-radius: 50%;
    border: none;
    background-color: #cccccc;
    color: #000;
}

#contact .contact-form .button-icon {
    font-size: 2rem;
    color: #969696;
    transition: 0.5s;
}

#contact .contact-form .button-icon:hover {
    color: #f2050e;
}

#contact .contact-details {
    font-weight: 300;
    color: #333333;
    font-size: 17px;
}

#contact .contact-details .touch {
    font-size: 25px;
}

#contact .contact-details strong {
    font-weight: 600;
}

@media screen and (max-width: 1200px) {
    #contact .contact-form {
        width: 600px;
    }
}

@media screen and (max-width: 992px) {
    #contact .contact-form {
        width: initial;
    }
} */

/* Map */
#contact-map .mapouter {
    position: relative;
    text-align: right;
    height: 408px;
    width: 100%;
}

#contact-map .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 408px;
    width: 100%;
}

/* Main */
#contact-main {
    font-family: Poppins;
}

#contact-main h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #af2c2b;
}

#contact-main .contact-form {
    width: 95%;
    box-shadow: 10px 24px 48px rgba(17, 16, 62, 0.12);
    border-radius: 12px;
}

#contact-main .contact-details {
    border-right: 1px solid #af2c2b;
}

#contact-main .contact-icon {
    color: #af2c2b;
    font-size: 1.2rem;
    margin-top: 0.1em;
}

#contact-main .contact-text {
    color: #9e9e9e;
}

#contact-main .contact-text a {
    text-decoration: none;
    color: #9e9e9e;
}

/* #contact-main form {
    color: #9e9e9e;
} */

#contact-main img {
    max-width: 100%;
    border-top-left-radius: 12px;
}

#contact-main .contact-info {
    width: 40%;
}

#contact-main form {
    width: 60%;
}

#contact-main form .form-label {
    color: #9e9e9e;
}

#contact-main form .form-control {
    border-radius: 0;
    border: 1px solid #9e9e9e;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#contact-main form button {
    border: none;
    border-radius: 0;
    background-color: #af2c2b;
    font-weight: 700;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}

@media (max-width: 992px) {
    #contact-main .contact-form,
    #contact-main .contact-info,
    #contact-main form {
        width: 100%;
    }

    #contact-main .contact-details {
        border-bottom: 5px solid #af2c2b;
        border-right: none;
    }

    #contact-main img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-top-right-radius: 12px;
    }
}
